.slider {
  margin-top: 24px;
  padding: 32px;
  background-color: #{$dark-grey};

  @include responsive(mb) {
    padding: 22px;
  }

  .slider-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  input[type="range"] {
    -webkit-appearance: none;
    height: 8px;
    width: 100%;
    background-color: #{$very-dark-grey};
    background-image: linear-gradient(#{$neon-green}, #{$neon-green});
    background-size: 0% 100%;
    background-repeat: no-repeat;
    margin-top: 26px;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    height: 8px;
  }

  input[type="range"]::-webkit-slider-thumb {
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -12px;
    background-color: #{$almost-white};
    width: 30px;
    height: 30px;
    border-radius: 100%;

    &:hover {
      border-radius: 28px;
      border: 2px solid #{$neon-green};
      background: #{$very-dark-grey};
    }
  }
}
