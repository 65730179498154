body {
  background-color: #{$very-dark-grey};
  /* TODO: need to import font */
  font-family: "JetBrainsMono-Bold", system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
  color: #{$almost-white};
  display: flex;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding-top: 64px;
}
