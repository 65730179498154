@font-face {
  font-family: "JetBrainsMono-Bold";
  src: url("../../../public/assets/fonts/static/JetBrainsMono-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "JetBrainsMono-BoldItalic";
  src: url("../../../public/assets/fonts/static/JetBrainsMono-BoldItalic.ttf");
  font-weight: bold;
}
