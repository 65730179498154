.container {
  margin-top: 31px;
  width: 540px;
  height: 632px;

  @include responsive(mb) {
    width: 343px;
  }
}

.checkbox:first-child {
  padding-top: 0px;
}
